<template>
  <div class="router-container flexbox vertical">
    <header class="title-bar" v-show="$route.meta.title">
      <div class="logo">
        <img :src="logoUrl" />
      </div>
      <span class="title"> {{ $route.meta.title }}</span>
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item
          v-model="lang"
          :options="option1"
          @change="onLangChange"
        />
      </van-dropdown-menu>
    </header>
    <router-view class="flex-fill" />
    <van-tabbar v-model="active" @change="onTabChange">
      <van-tabbar-item name="Home">
        <div class="x-center">
          {{ $t('App.l72v0c') }}
        </div>
        <template #icon="props">
          <div
            class="tab-icon"
            :class="props.active ? 'home-active' : 'home'"
          ></div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="orders-o" name="Orders">
        <span>{{ $t('App.zkemzf') }}</span>
        <template #icon="props">
          <div
            class="tab-icon"
            :class="props.active ? 'orders-active' : 'orders'"
          ></div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item class="deliver" icon="free-postage" name="Deliver">
        <span class="layover">{{ $t('App.3a0eq6') }}</span>
        <template #icon="props">
          <div
            class="tab-icon"
            :class="props.active ? 'deliver' : 'deliver'"
          ></div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="location-o" name="Tracks">
        {{ $t('App.59v34m') }}
        <template #icon="props">
          <div
            class="tab-icon"
            :class="props.active ? 'location-active' : 'location'"
          ></div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="user-o" name="Account"
        >{{ $t('App.mc31tk') }}
        <template #icon="props">
          <div
            class="tab-icon"
            :class="props.active ? 'profile-active' : 'profile'"
          ></div>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n';
import store from '@/store';
import { getSysConf } from '../api/website.js';
export default {
  data() {
    return {
      active: 'Home',
      lang: 'zh',
      option1: [
        { text: '中文', value: 'zh' },
        { text: 'En(US)', value: 'en' },
      ],
      //'http://tms.yc-5566.com/Images//firms/gzyc/logo111.png',
      logoUrl:
        'https://qusutms.oss-cn-shenzhen.aliyuncs.com/demo/logoqusu-mini_w.png',
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.active = route.name;
      },
      immediate: true,
    },
  },
  mounted() {
    if (store.getters.language == undefined) {
      // store.getters.language = 'zh';
      // i18n.locale = 'zh';
      var language = (
        navigator.browserLanguage || navigator.language
      ).toLowerCase();
      if (language.indexOf('zh') > -1) {
        store.getters.language = 'zh';
        i18n.locale = 'zh';
      } else if (language.indexOf('en') > -1) {
        store.getters.language = 'en';
        i18n.locale = 'en';
      } else {
        store.getters.language = 'en';
        i18n.locale = 'en';
      }
    }
    console.log(store.getters.language);
    this.lang = store.getters.language;

    getSysConf('TMSMiniLogo').then(res => {
      this.logoUrl = res.Info;
      //this.data.logoUrl = res.Info;
    });
  },
  methods: {
    onTabChange(name) {
      this.$router.replace({
        name,
      });
    },
    onLangChange(lang) {
      console.log(store.getters.language);
      store.getters.language = lang;
      i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.tab-icon {
  width: rem(44px);
  height: rem(44px);
  background-size: contain;
  background-repeat: no-repeat;
  &.home {
    background-image: url(~@/assets/images/home.png);
  }
  &.home-active {
    background-image: url(~@/assets/images/home-active.png);
  }
  &.orders {
    background-image: url(~@/assets/images/orders.png);
  }
  &.orders-active {
    background-image: url(~@/assets/images/orders-active.png);
  }
  &.location {
    background-image: url(~@/assets/images/location.png);
  }
  &.location-active {
    background-image: url(~@/assets/images/location-active.png);
  }
  &.profile {
    background-image: url(~@/assets/images/profile.png);
  }
  &.profile-active {
    background-image: url(~@/assets/images/profile-active.png);
  }
  &.deliver {
    background-image: url(~@/assets/images/deliver.png);
  }
}
.van-tabbar-item.deliver {
  position: relative;
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: -30%;
  }
}

.deliver .layover {
  z-index: 999;
}

.title-bar {
  .logo {
    width: 20%;
    float: left;
    position: relative;
    img {
      width: 25px;
      padding: 0;
      float: left;
      margin-left: 7px;
    }
  }
  .title {
    width: 60%;
    float: left;
    position: relative;
    text-align: center;
  }
  .van-dropdown-menu {
    width: 20%;
    float: right;
    position: relative;
    height: 20px;
  }
}
.van-dropdown-menu__bar {
  height: 25px;
}
</style>
