<template>
  <div class="home">
    <div class="">
      <van-swipe
        class="my-swipe banner"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item
          v-for="item in newsList"
          :key="item.PK_ID"
          @click="
            $router.push({ name: 'NewsDetail', query: { id: item.PK_ID } })
          "
        >
          <img v-lazy="item.sImage1" style="width: 100%; height: 100%" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="notif flexbox align-center">
      <van-icon name="bell" />
      <div
        v-if="notifyData != null"
        @click="
          $router.push({
            name: 'NotifyDetail',
            query: { id: notifyData.PK_ID },
          })
        "
        class="notif-content"
      >
        {{ notifyData != null ? notifyData.sTitle : '暂时没有消息' }}
      </div>
      <div v-else class="notif-content">暂时没有系统通知</div>
      <van-icon
        name="ellipsis"
        class="ml-auto"
        @click="$router.push({ name: 'NotifyList' })"
      />
    </div>
    <!--公司简介--->
    <van-grid :column-num="2" clickable class="van-grid-item__icon">
      <van-grid-item
        class=""
        icon="wap-home"
        :text="$t('Home.收货仓库')"
        @click="
          $router.push({ name: 'RecStorageAddr', query: { key: '收货仓库' } })
        "
      >
      </van-grid-item>
      <van-grid-item
        icon="award"
        :text="$t('Home.公司简介')"
        @click="$router.push({ name: 'FirmDetail' })"
      >
      </van-grid-item>
      <!-- <van-grid-item
        icon="chat"
        :text="$t('Home.联系我们')"
        @click="
          $router.push({ name: 'NewsDetail', query: { key: '联系我们' } })
        "
      >
      </van-grid-item> -->
    </van-grid>

    <!-- 公司服务-->
    <van-grid :column-num="3" clickable class="van-grid-item__icon">
      <van-grid-item
        class=""
        icon="point-gift-o"
        :text="$t('Home.自提服务')"
        @click="
          $router.push({ name: 'NewsDetail', query: { key: '自提服务' } })
        "
      >
      </van-grid-item>
      <van-grid-item
        icon="newspaper-o"
        :text="$t('Home.新手手册')"
        @click="
          $router.push({ name: 'NewsDetail', query: { key: '新手手册' } })
        "
      >
      </van-grid-item>
      <van-grid-item
        icon="bill-o"
        :text="$t('Home.收费标准')"
        @click="
          $router.push({ name: 'NewsDetail', query: { key: '收费标准' } })
        "
      >
      </van-grid-item>
      <van-grid-item
        icon="vip-card-o"
        :text="$t('Home.保险理赔')"
        @click="
          $router.push({ name: 'NewsDetail', query: { key: '保险服务' } })
        "
      />
      <van-grid-item
        icon="label-o"
        :text="$t('Home.违禁品告示')"
        @click="
          $router.push({ name: 'NewsDetail', query: { key: '违禁品告示' } })
        "
      />
      <van-grid-item
        icon="smile-comment-o"
        :text="$t('Home.联系客服')"
        @click="
          $router.push({ name: 'NewsDetail', query: { key: '联系客服' } })
        "
      />
    </van-grid>

    <van-tabs style="display: none" v-model="active">
      <van-tab :title="$t('Home.ix1jlt')">
        <div class="flexbox align-center form-row">
          <span class="field-label">{{ $t('Home.ou0tfv') }}</span>
          <div class="number-spinner round flexbox">
            <div
              class="spinner minus"
              @click="weight = Math.max(+weight - 1, 0)"
            >
              <van-icon name="minus" />
            </div>
            <van-field v-model="weight" type="number" />
            <div class="spinner plus" @click="weight = +weight + 1">
              <van-icon name="plus" />
            </div>
          </div>
        </div>
        <div class="flexbox align-center form-row">
          <span class="field-label">{{ $t('Home.gsr8sx') }}</span>
          <van-field
            v-model="length"
            :placeholder="$t('Home.l5w8fq')"
            type="number"
          />
          <van-field
            v-model="width"
            :placeholder="$t('Home.v5wnxc')"
            type="number"
          />
          <van-field
            v-model="height"
            :placeholder="$t('Home.uksvhr')"
            type="number"
          />
        </div>
        <div class="flexbox align-center form-row">
          <mobile-picker
            class="round"
            :items="regions"
            v-model="region"
          ></mobile-picker>
          <van-field
            v-model="result"
            :placeholder="$t('Home.xtlazn')"
            readonly
          />
          <van-button type="info" size="small">{{
            $t('Home.z896le')
          }}</van-button>
        </div>
      </van-tab>
      <van-tab :title="$t('Home.汇率计算')">
        <div class="flexbox align-center form-row">
          <mobile-picker
            class="flex-fill"
            :items="currencyOptions"
            v-model="sourceCurrency"
          ></mobile-picker>
          <van-icon name="exchange" />
          <mobile-picker
            class="flex-fill"
            :items="currencyOptions"
            v-model="destCurrency"
          ></mobile-picker>
        </div>
        <div class="form-row flexbox align-center">
          <div class="field-label fixed-width">{{ $t('Home.4l6v6e') }}</div>
          <van-field v-model="amount" type="number"></van-field>
        </div>
        <div class="flexbox form-row align-center">
          <div class="field-label fixed-width">
            {{ $t('Home.zol917') }}
          </div>
          <van-field v-model="currencyResult" readonly></van-field>
        </div>
      </van-tab>
    </van-tabs>

    <!--主营业务-->
    <div class="color-3 group-title flexbox align-center">
      <span>{{ $t('Home.ango7f') }}</span>
      <span
        class="ml-auto flexbox align-center"
        @click="$router.push({ name: 'MainService' })"
      >
        <span>{{ $t('Home.more') }}</span>
        <van-icon name="arrow" />
      </span>
    </div>
    <div class="flexbox">
      <div class="card round" v-for="item in serviceList" :key="item.sPicUrl">
        <img
          :src="baseUrl + item.sPicUrl"
          @click="
            $router.push({ name: 'ServiceDetail', query: { id: item.PK_ID } })
          "
        />
      </div>
    </div>
    <!--特色服务-->
    <div class="color-3 group-title flexbox align-center">
      <span>{{ $t('Home.specialservice') }}</span>
      <span
        class="ml-auto flexbox align-center"
        @click="$router.push({ name: 'MainBusiness' })"
      >
        <span>{{ $t('Home.more') }}</span>
        <van-icon name="arrow" />
      </span>
    </div>
    <div class="flexbox">
      <div class="card round" v-for="item in projectList" :key="item.sImageUrl">
        <img
          :src="baseUrl + item.sImageUrl"
          @click="
            $router.push({ name: 'BizDetail', query: { id: item.PK_ID } })
          "
        />
      </div>
    </div>

    <div class="color-3 group-title flexbox align-center">
      <span>{{ $t('Home.press') }}</span>
      <span
        class="ml-auto flexbox align-center"
        @click="$router.push({ name: 'NewsList' })"
      >
        <span>{{ $t('Home.more') }}</span>
        <van-icon name="arrow" />
      </span>
    </div>
    <div class="box">
      <div class="news-list" v-for="item in newsList" :key="item.PK_ID">
        <div
          @click="
            $router.push({ name: 'NewsDetail', query: { id: item.PK_ID } })
          "
        >
          <div class="col-xs-4">
            <img :src="item.sImage1" />
          </div>
          <div class="col-xs-8">
            <div class="title">{{ item.sTitle }}</div>
            <time class="tm">{{ item.dCreateTime }}</time>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '@/api/index.js';
import {
  getProjectList,
  queryNews,
  getServiceList,
  getLastMess,
  getLastNotify,
} from '@/api/website.js';
import MobilePicker from '../components/MobilePicker.vue';
import { formatDate } from '@/utils/index.js';
// @ is an alias to /src

function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate);
}

export default {
  name: 'Home',
  components: { MobilePicker },
  data() {
    return {
      baseUrl: BASE_URL,
      active: 0,
      weight: 0,
      length: '',
      width: '',
      height: '',
      result: '',
      region: 1,
      regions: [
        {
          id: 1,
          label: '美国',
        },
        {
          id: 2,
          label: '柬埔寨',
        },
        {
          id: 3,
          label: '新加坡',
        },
      ],
      currencyOptions: [
        {
          id: 1,
          label: 'USD',
        },
        {
          id: 2,
          label: 'CNY',
        },
      ],
      sourceCurrency: '',
      destCurrency: '',
      amount: '',
      currencyResult: '',
      messData: null,
      notifyData: null,
      projectList: [],
      newsList: [],
      serviceList: [],
    };
  },
  created() {
    var usrInfo = this.$store.getters.userInfo;

    if (this.isWeiXin()) {
      var openid = this.$route.query.openid;
      //alert(openid);
      if (openid == undefined || openid == null || openid == '') {
        if (usrInfo == null || usrInfo == undefined) {
          location.href = BASE_URL + '/customer/RegWeiXinAuth';
        }
      } else {
        // alert(22222);
        this.$router.push({
          name: 'Login',
          query: { openid: openid },
        });
      }
    }

    this.loadData();
  },
  methods: {
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    loadData() {
      queryNews().then(res => {
        if (res.Result == true) {
          this.newsList = res.DataDict['Data'];
          this.newsList.forEach(function(item, index, arr) {
            arr[index].dCreateTime = getValidTime(item.dCreateTime);
            var urlImg = arr[index].sImage1;
            if (urlImg.startsWith('http') == false) {
              arr[index] = BASE_URL + urlImg;
            }
          });
        }
      });
      getServiceList(3).then(res => {
        if (res.Result == true) {
          this.serviceList = res.DataDict['data'];
        }
      });
      getProjectList(2).then(res => {
        if (res.Result == true) {
          this.projectList = res.DataDict['Data'].data;
        }
      });
      getLastMess().then(res => {
        if (res.Result == true) {
          this.messData = res.DataDict['data'].data;
        }
      });
      getLastNotify().then(res => {
        if (res.Result == true) {
          this.notifyData = res.DataDict['data'];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.home {
  padding: rem(20px);
}
.banner {
  height: rem(220px);
  border-radius: rem(15px);
  margin-bottom: rem(20px);
  // background: linear-gradient(0deg, #502abd 0%, #8e79c9 98%);
}
.notif {
  height: rem(60px);
  line-height: rem(60px);
  padding: 0 rem(20px);
  border-radius: rem(15px);
  background: #e69438;
  color: #fff;
  margin-bottom: rem(40px);
  .notif-content {
    margin-left: rem(22px);
  }
}
.van-tabs {
  margin-bottom: rem(50px);
}
/deep/ .van-tabs__content {
  background-color: #fff;
  padding: rem(40px);
}
.field-label {
  color: #9c9bb4;
  white-space: nowrap;
  margin-right: rem(60px);
  &.fixed-width {
    flex-basis: rem(120px);
    flex-shrink: 0;
  }
}
.van-field {
  border-radius: rem(15px);
  border: 1px solid #9c9bb4;
  padding: rem(18px);
  line-height: 1;
  margin-left: rem(10px);
  font-size: 12px;
}
.number-spinner {
  border: 1px solid #9c9bb4;
  .spinner {
    background-color: #f6f6f6;
    width: rem(60px);
    text-align: center;
    line-height: rem(60px);
    &.minus {
      border-top-left-radius: rem(15px);
      border-bottom-left-radius: rem(15px);
    }
    &.plus {
      border-top-right-radius: rem(15px);
      border-bottom-right-radius: rem(15px);
    }
  }
  .van-field {
    border: none;
    &::after {
      border: none;
    }
  }
}
.mobile-picker {
  background-color: #f6f6f6;
  white-space: nowrap;
}
.form-row {
  & + .form-row {
    margin-top: rem(40px);
  }
}
.van-button {
  width: rem(160px);
  text-align: center;
  background-color: $theme-color;
  margin-left: rem(10px);
  flex-shrink: 0;
  border-color: $theme-color;
}
.card {
  height: rem(180px);
  background-color: #fff;
  flex: 1;
  & + .card {
    margin-left: rem(50px);
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
.news-list {
  margin: 5px 0;
  height: rem(160px);
  background-color: #fff;
  flex: 1;
  img {
    width: 80%;
    height: rem(130px);
    border-radius: 5px;
    padding: 5px 10px;
  }
  .title {
    padding: 10px 5px;
    position: relative;
    float: left;
    width: 95%;
  }
  .tm {
    color: #adadad;
    padding: 10px 5px;
  }
}

.group-title {
  margin-bottom: rem(26px);
  margin-top: rem(10px);
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #b6ccda;
}

.van-grid {
  margin-bottom: 15px;
}

.van-grid-item__icon {
  color: #9c9bb6;
}
</style>
